<template>
  <div class="container">
    <div class="col-lg-6">
      <div class="d-flex w-100 flex-nowrap align-content-center align-items-end">
        <p-input label="Админ-ключ" class="w-100" v-model:value="state.adminKey" margin="" required/>
        <button class="btn ms-3" :class="state.adminKeyValid ? 'btn-success' : 'btn-outline-dark'" @click.prevent="load">
          {{ !state.adminKeyValid ? 'Проверить' : 'Изменить' }}
        </button>
      </div>
      <p-server-error :error="state.adminKeyError"/>
      <div v-if="state.adminKeyValid && !state.creating">
        <hr>
        <p-ticker-form :no-worker-mode="true" v-model:ticket="state.ticket" @update:ticket="updateTicket"/>
        <p-multiselect class="mb-3" v-model:value="state.lab" v-if="state.labs.length > 0"
                       :options="state.labs" searchPlaceholder="Начните вводить название" :noResultsCreateItem="false"
                       label="Поверочная лаборатория" placeholder="Поверочная лаборатория" optionLabel="name" required/>

        <button class="btn btn-dark w-100" @click.prevent="create">
          Создать
        </button>
        <p-server-error :error="state.formError"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import PTickerForm from '@/components/forms/Ticket'
import PServerError from '@/components/ui/ServerError'
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { serializeDate } from '@/common'

const store = useStore()
const state = reactive({
  adminKey: null,
  adminKeyValid: false,
  adminKeyError: null,

  labs: [],
  homes: [],

  lab: null,
  ticket: {},
  formError: null,
  creating: false,
})

const updateTicket = (newTicket) => {
  state.ticket = newTicket
}

const create = async () => {
  try {
    await api.post(urls.TICKETS.NEW_ADMIN, {
      ...state.ticket,
      date: serializeDate(state.ticket.date),
      homeId: state.ticket.address.id,
      labId: state.lab.id,
    })
    state.creating = true
    state.ticket = {}
    state.lab = null
    setTimeout(() => { state.creating = false }, 100)
  } catch (err) {
    state.formError = err.message
  }
}

const load = async () => {
  store.commit('setAdminKey', state.adminKey)
  state.adminKeyError = null
  state.adminKeyValid = false

  try {
    state.homes = await api.get(urls.HOME.AVAILABLE)
    state.labs = await api.get(urls.COMPANY.ADMIN_LABS)
    state.adminKeyValid = true
  } catch (err) {
    store.commit('setAdminKey', null)
    state.adminKeyError = 'Ключ неверный'
  }
}

onMounted(async () => {
  state.adminKey = store.state.adminKey
  await load()
})
</script>

<style scoped>
</style>
