<template>
  <form>
    <p-input label="Фамилия" v-model:value="state.data.lastName" :error="v$.lastName.$errors" :disabled="disabled"
             required/>
    <p-input label="Имя" v-model:value="state.data.firstName" :error="v$.firstName.$errors" :disabled="disabled"
             required/>
    <p-input label="Отчество" v-model:value="state.data.middleName" :disabled="disabled"/>

    <p-input label="Номер телефона" v-model:value="state.data.phone" :error="v$.phone.$errors"
             maska="+7 (###) ###-##-##" :disabled="disabled" required/>
    <p-input label="Email" v-model:value="state.data.email" :error="v$.email.$errors" :disabled="disabled"/>

    <div class="d-flex">
      <p-multiselect class="mb-3 w-100" v-model:value="state.data.address" :error="v$.address.$errors"
                     :disabled="disabled"
                     :options="state.addresses" searchPlaceholder="Начните вводить адрес" :noResultsCreateItem="false"
                     label="Адрес" placeholder="Адрес" optionLabel="name" required/>

      <p-input class="w-25 ms-5" label="Квартира" v-model:value="state.data.apt" :error="v$.apt.$errors"
               :disabled="disabled" required/>
    </div>

    <p-select v-model:value="state.data.workType" class="mb-3" label="Вид работ" :options="workTypes"
              :disabled="disabled" :error="v$.workType.$errors" required/>

    <div class="d-flex">
      <p-datetime-picker class="w-100 me-3" label="Дата поверки" v-model:value="state.data.date" :disabled="disabled"
                         :error="v$.date.$errors" required/>
      <p-input class="ps-4 w-50" label=" " placeholder="c" v-model:value="state.data.timeStart" :disabled="disabled"
               type="number" :error="v$.timeStart.$errors"/>
      <p-input class="ps-4 w-50" label=" " placeholder="по" v-model:value="state.data.timeEnd" :disabled="disabled"
               type="number" :error="v$.timeEnd.$errors"/>
    </div>
    <p-multiselect class="mb-3" v-model:value="state.data.worker" :error="v$.worker.$errors" :disabled="disabled"
                   :options="state.employees" searchPlaceholder="Начните вводить имя" :noResultsCreateItem="false"
                   label="Исполнитель" placeholder="Исполнитель" optionLabel="name" v-if="isLab" :required="workerRequired"/>
    <p-input label="Комментарий" v-model:value="state.data.comment" :disabled="disabled" v-if="isLab"/>

    <button class="btn btn-dark px-5" @click.prevent="submit" :disabled="disabled || !requiredFilled" v-if="isLab">
      {{ props.ticket ? 'Сохранить' : 'Создать' }}
    </button>
    <p-server-error :error="serverError"/>
    <div></div>
  </form>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { prepareNumber, requiredRu, serializeDate } from '@/common'
import PServerError from '@/components/ui/ServerError'
import PDatetimePicker from '@/components/ui/DatetimePicker'
import form from '@/composables/form'
import { email, helpers } from '@vuelidate/validators'
import { computed, defineEmits, defineProps, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  data: {
    lastName: null,
    firstName: null,
    middleName: null,
    phone: null,
    email: null,
    address: null,
    apt: null,
    workType: null,
    date: null,
    timeStart: null,
    timeEnd: null,
    worker: null,
    comment: null,
  },
  addresses: [],
  employees: [],
})
const emit = defineEmits(['created', 'update:ticket'])
const props = defineProps({
  ticket: {
    type: Object,
    required: false,
    default: null,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  noWorkerMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  workerRequired: {
    type: Boolean,
    required: false,
    default: true,
  },
})
const isLab = computed(() => !props.noWorkerMode && store.state.company && store.state.company.kind === 'lab')

const workTypes = [
  {
    name: 'Поверка счетчика',
    value: 'Поверка счетчика',
  },
  {
    name: 'Замена счетчика',
    value: 'Замена счетчика',
  },
  {
    name: 'Установка счетчика',
    value: 'Установка счетчика',
  },
]

const {
  v$,
  serverError,
  requiredFilled,
  submit,
} = form(state.data, {
  lastName: { requiredRu },
  firstName: { requiredRu },
  phone: {
    requiredRu,
    valid: helpers.withMessage(
      'Пожалуйста, введите верный номер телефона',
      (value) => !value || value.length === 18
    ),
  },
  email: {
    email: helpers.withMessage('Пожалуйста, введите верный email', email),
  },
  address: { requiredRu },
  apt: { requiredRu },
  workType: { requiredRu },
  date: { requiredRu },
  timeStart: { },
  timeEnd: { },
  worker: props.workerRequired ? { requiredRu } : {},
}, async () => {
  const ticket = await api.post(urls.TICKETS.NEW, {
    ticketId: props.ticket ? props.ticket.id : null,
    lastName: state.data.lastName,
    firstName: state.data.firstName,
    middleName: state.data.middleName || null,
    phone: state.data.phone,
    email: state.data.email || null,
    homeId: state.data.address.id,
    apt: state.data.apt,
    workType: state.data.workType,
    date: serializeDate(state.data.date),
    timeStart: prepareNumber(state.data.timeStart),
    timeEnd: prepareNumber(state.data.timeEnd),
    workerId: state.data.worker ? state.data.worker.id : null,
    comment: state.data.comment,
  })
  if (props.ticket === null) {
    store.commit('setNewTicket', null)
    Object.assign(state.data, {
      lastName: null,
      firstName: null,
      middleName: null,
      phone: null,
      email: null,
      address: null,
      apt: null,
      workType: null,
      date: null,
      timeStart: null,
      timeEnd: null,
      worker: null,
      comment: null,
    })
  }
  emit('created', ticket)
})

watch(
  () => state.data.lastName + state.data.firstName + state.data.middleName + state.data.phone +
    state.data.email + state.data.address + state.data.workType + state.data.date + state.data.timeStart +
    state.data.timeEnd + state.data.worker + state.data.apt + state.data.comment,
  () => {
    emit('update:ticket', state.data)
    if (props.ticket === null) {
      store.commit('setNewTicket', state.data)
    }
  }
)
onMounted(async () => {
  state.addresses = (await api.get(urls.HOME.AVAILABLE)).map(home => ({
    ...home,
    name: `г. ${home.city}, ${home.street} ${home.streetNumber}`,
  }))
  if (isLab.value) {
    state.employees = (await api.get(urls.COMPANY.EMPLOYEES)).map(employee => ({
      ...employee,
      name: `${employee.lastName} ${employee.firstName} ${employee.middleName ? employee.middleName : ''}`,
    }))
  }
  if (props.ticket === null) {
    if (store.state.newTicket) {
      Object.assign(state.data, store.state.newTicket)
    }
  } else {
    state.data.lastName = props.ticket.lastName
    state.data.firstName = props.ticket.firstName
    state.data.middleName = props.ticket.middleName
    state.data.phone = props.ticket.phone
    state.data.email = props.ticket.email
    state.data.comment = props.ticket.comment
    if (props.ticket.home && props.ticket.home.id) {
      state.data.address = state.addresses.filter(address => address.id === props.ticket.home.id)[0]
    }
    state.data.apt = props.ticket.apt
    if (props.ticket.workType) {
      state.data.workType = workTypes.filter(({
        name,
        value,
      }) => props.ticket.workType === name)[0].value
    }
    state.data.date = props.ticket.date
    state.data.timeStart = props.ticket.timeStart
    state.data.timeEnd = props.ticket.timeEnd
    if (isLab.value) { state.data.worker = state.employees.filter(employee => employee.id === props.ticket.workerId)[0] }
  }
})
</script>

<style scoped>

</style>
